import { CTALink, Image } from '@finn/ui-components';
import { cn, getCloudinaryImgUrl } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Head from 'next/head';
import React from 'react';

import { StepperItemCosmicMetadata } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 32,
    height: 32,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  imageWrapper: {
    width: '100%',
    paddingTop: '60%',
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      left: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      maxWidth: '100vw',
      right: '50%',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%,-50%,0)',
    minHeight: '100%',
    width: '100%',
  },
  body: {
    maxHeight: theme.spacing(12),
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    marginBottom: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(2),
  },
}));

interface IProps {
  item: StepperItemCosmicMetadata;
  isManagementCard?: boolean;
}

const StepperItem: React.FunctionComponent<IProps> = ({
  item,
  isManagementCard,
}) => {
  const classes = useStyles();

  return (
    <>
      <Head>
        {item.icon?.url && (
          <link rel="preload" href={item.icon.url} as="image" />
        )}
      </Head>
      <Box>
        {item.icon?.url && (
          <Image
            className={classes.icon}
            src={item.icon?.url}
            alt={item.title}
          />
        )}
        {item.image?.url && (
          <Box className={classes.imageWrapper}>
            <Image
              variant="responsive"
              className={classes.image}
              smImage={getCloudinaryImgUrl(item.image?.url, { w: 375 })}
              mdImage={getCloudinaryImgUrl(item.image?.url, { w: 404 })} // duplicated large as we not follow design yet
              lgImage={getCloudinaryImgUrl(item.image?.url, { w: 404 })}
              alt={item.title}
            />
          </Box>
        )}
        {item.subtitle && (
          <h6 className="global-t6-semibold mb-4">
            {parseToHtml(item.subtitle)}
          </h6>
        )}
        <Box>
          <h5 className="global-t5-semibold mb-4">{parseToHtml(item.title)}</h5>
          <p
            className={cn('body-16-light mb-2', {
              [cn(classes.body, 'mb-4')]: !isManagementCard,
            })}
          >
            {parseToHtml(item.description)}
          </p>
        </Box>
        {item.link_href && (
          <CTALink
            className={classes.link}
            href={item.link_href}
            text={item.link_text}
          />
        )}
      </Box>
    </>
  );
};

export default StepperItem;
